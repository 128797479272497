 <template>
  <div>
    <CModal color="danger" :show="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Product Group</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ product_group ? product_group.name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteProductGroup(product_group)" v-else>
          Delete Product Group
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductGroupDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.product_groups.deleteModal,
      product_group: (state) => state.product_groups.deleteProductGroup,
      isSaving: (state) => state.product_groups.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("product_groups/DELETE_MODAL", { bool: bool, product_group: null });
    },
    deleteProductGroup(product_group) {
      this.$store.dispatch("product_groups/destroyProductGroup", { 
        product_group: product_group
      });
    },
  },
};
</script>